import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import OrganisationCard from "./OrganisationCard";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import FilterTag from "./FilterTag";
import { UserAnswerProps } from "../../utils/types/services/userAnswer";
import { UserResult } from "../../utils/types/services/userResult";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SortRoundedIcon from "@mui/icons-material/SortRounded";
import followUpIcon from "../../images/followUpIcon.svg";
import { useQuery } from "@tanstack/react-query";
import { getTheme } from "../../services/theme";
import REACT_QUERY_KEYS from "../../utils/constants/reactQueryKeys";
import { useLocation } from "react-router-dom";
import SupportRequestSection from "./SupportRequestSection";


interface FilteredSupportProps {
 page: number;
 setPage: (page: number) => void;
 data: UserAnswerProps;
 setData: (value: React.SetStateAction<UserAnswerProps>) => void;
 userResult: UserResult | undefined;
 setUserResult: (value: UserResult | undefined) => void;
}


const FilteredSupport = (props: FilteredSupportProps) => {
 const filterTagData = props.userResult?.filterTagResult;
 const organisationData = props.userResult?.organisationResult;


 const location = useLocation();
 const { data: themeData, isLoading } = useQuery(
   [REACT_QUERY_KEYS.DB_PUBLIC_THEME_DATA],
   () => getTheme(location.pathname),
   {
     enabled: true,
     retry: true,
   },
 );
 const cancer_logo = themeData?.data?.icon;


 const theme = useTheme();
 const initialFilterTagData = filterTagData
   ? filterTagData.map((item) => item.name)
   : [""];


 useEffect(() => {
   setSelectedCategory(initialFilterTagData);
 }, [filterTagData]);
 const [selectedCategory, setSelectedCategory] =
   useState<string[]>(initialFilterTagData);


 const data = useMemo(() => {
   if (!organisationData) return;
   let dataClone = [...organisationData];
   if (selectedCategory !== null) {
     dataClone = dataClone.filter((e) =>
       e.chipsTypes
         .map((item) => item.name)
         .some((e: string) => selectedCategory.includes(e)),
     );
   }
   return dataClone;
 }, [organisationData, selectedCategory]);


 const shouldDisableFields = !!(data && data.length > 0);


 return (
   <Grid sx={{ marginBottom: 18 }}>
     <Grid sx={{ paddingBottom: 1 }}>
       <Grid xs={12} container item justifyContent="center">
         <img
           src={cancer_logo}
           style={{ height: 60, marginTop: 15, marginBottom: 5 }}
         />
       </Grid>
       <Grid
         xs={12}
         container
         item
         justifyContent="center"
         sx={{ paddingLeft: 2, paddingRight: 2 }}
       >
         <Typography variant="h4">
           我們根據你的需要為你成功配對了以下支援
         </Typography>
       </Grid>
     </Grid>


     <Grid
       sx={{
         margin: {
           xs: "0% 5% 0 5%",
         },
       }}
     >
       <Grid
         container
         item
         sx={{ display: "flex", alignItems: "center", paddingBottom: 0 }}
       >
         <Typography
           variant="body2"
           sx={{ color: "black", paddingRight: 1, paddingTop: 1 }}
         >
         
         <Typography
            variant="body2"
            sx={{ display: "flex",
              alignItems: "center",
              color: "#222222",
              paddingX: 1,
              paddingY: 1,
              fontFamily: "Roche Sans",
              fontWeight: 400,
              fontSize: "20px",
              lineHeight: "27px" }}
          >
            篩選
          </Typography> 
         </Typography>
         {filterTagData ? (
           filterTagData.map((data, index) => (
             <Box key={index}>
               <FilterTag
                 name={data.name}
                 color={data.color}
                 selectedCategory={selectedCategory}
                 setSelectedCategory={setSelectedCategory}
               />
             </Box>
           ))
         ) : (
           <Grid>No filter Tag Data</Grid>
         )}
         <Grid container item justifyContent="end">
           <Typography variant="body1" sx={{ color: "#999999" }}>
             {data?.length} 個結果
           </Typography>
         </Grid>
       </Grid>
       <Grid
         container
         sx={{ direction: "row", justifyContent: "space-between" }}
       >
         {data ? (
           data.map((card, index) => (
             <Grid
               key={index}
               container
               item
               xs={12}
               sm={12}
               md={6}
               lg={6}
               xl={6}
             >
               <OrganisationCard
                 name={card.name}
                 service={card.service}
                 address={card.address}
                 contact_number={card.contact_number}
                 opening_hour={card.opening_hour}
                 google_map_link={card.google_map_link}
                 website={card.website}
                 participationform={card.participationform}
                 // chipsTypes={card.chipsTypes.filter((x) =>
                 //   filterTagData?.some((y) => y.name === x.name),
                 // )}
                 chipsTypes={card.chipsTypes}
               />
             </Grid>
           ))
         ) : (
           <Grid>No data</Grid>
         )}
       </Grid>
     </Grid>


     <Grid sx={{ position: "fixed", bottom: 30, right: 0 }}>
 <Button
   color="primary"
   variant="contained"
   onClick={() => {
     props.setPage(0);
     props.setData({
       form1: [],
       form2: [],
       form3: [],
       form4: [],
       form5: [],
       form6: [],
       form7: [],
       form8: [],
       form9: [],
       form10: []
     });
     props.setUserResult({
       filterTagResult: [],
       organisationResult: [],
       userId: undefined,
     });
   }}
   sx={{
     borderRadius: 0,
     borderTopLeftRadius: 10,
     borderBottomLeftRadius: 10,
     width: {
       md: 130,
       lg: 140,
       xl: 150,
     },
     height: {
       md: 80,
       lg: 85,
       xl: 90,
     },
   }}
 >
   <Grid>
     <Grid>
       <CachedRoundedIcon />
     </Grid>
     <Grid
       fontSize={{
         md: "17px",
         lg: "17px",
         xl: "17px",
       }}
     >
       重做一次
     </Grid>
   </Grid>
 </Button>
</Grid>


<SupportRequestSection userId={props.userResult?.userId ?? 0} disableFields={shouldDisableFields}  />
   </Grid>
 );
};


export default FilteredSupport;




