import React, { useState, useEffect } from "react";
import { Grid, Typography, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import DOMPurify from "dompurify";
import { OptionSet } from "../../utils/types/services/optionSet";
import { DropdownSet } from "../../utils/types/services/dropdownSet";
import SelectBaseButton from "../shareComponents/button/SelectBaseButton";
import useButtonCSSHook from "./ButtonCSSHook";

const NUMBER_3 = 3;

interface DiagnosisProps {
  data: string[];
  updateAnswer3: (data: string[]) => void;
  optionSet: OptionSet[] | undefined;
  dropdownset?: DropdownSet[];
}

const DiagnosisForm = (props: DiagnosisProps) => {
  const { updateAnswer3, data, dropdownset } = props;
  const { selectedBtn, defaultBtn } = useButtonCSSHook();
  const [selectedOption1, setSelectedOption1] = useState<string>("");
  const [selectedOption2, setSelectedOption2] = useState<string>("");
  const [buttonStyles, setButtonStyles] = useState<Record<string, any>>({});




  // Use useEffect to set input1 to props.data[0] when the component mounts
  useEffect(() => {
  
    setSelectedOption1(props.data[props.data.findIndex(str => str.includes("95"))] || ""); // Set input1 to props.data[0] or an empty string if props.data[0] is undefined
    setSelectedOption2(props.data[props.data.findIndex(str => str.includes("96"))] || "");
    setButtonStyles({ [data[0]]: selectedBtn });
   
    
  }, [props.data]); // Run this effect whenever props.data changes







  const questionSet = props.optionSet
    ? props.optionSet.filter((option) => option.formStep === NUMBER_3)
    : [];

  const handleOptionClick = (value: string) => {
 
    if (data.includes(value)) {

      updateAnswer3([]);
      setButtonStyles({});
      
    
    } else {
      
      props.data[0]=value;
      
      
      
      
      setButtonStyles({ [value]: selectedBtn });
      

      
      
      
    }
    
    
  };


  



  const handleInputChange = (e: SelectChangeEvent<string>, setInput: React.Dispatch<React.SetStateAction<string>>) => {
    setInput(e.target.value);
    if(e.target.value===""){
      props.data.splice(2, 1);
      props.data.splice(1, 1);
      setSelectedOption2('');
      setSelectedOption1('');
      updateAnswer3([]);
      setButtonStyles({});

    }

    

    if(!(props.data.some(str => str.includes(getSubstringUpToSpace(e.target.value)))) && (e.target.value.length!==0)){
      props.data.push(e.target.value);
     
    }
    else if((findSubstringIndex(props.data,getSubstringUpToSpace(e.target.value)))>-1 && (e.target.value.length!==0)){
      const oldData = [...props.data];
      oldData[findSubstringIndex(props.data,getSubstringUpToSpace(e.target.value))]=e.target.value;
      props.data=oldData;
    }

    if(props.data.length===NUMBER_3)
      props.updateAnswer3(props.data);
    

  };

  const getSubstringUpToSpace = function(mainString: string) {
    // Find the index of the first space
    const spaceIndex = mainString.indexOf(' ');
    
    // If spaceIndex is -1, it means there's no space in the string
    // In that case, return the whole string
    if (spaceIndex === -1) {
        return mainString;
    }
    
    // Return the substring from index 0 to spaceIndex
    return mainString.substring(0, spaceIndex);
};


const findSubstringIndex = function(array: string[], substring: string) {
  for (let i = 0; i < array.length; i++) {
    if (getSubstringUpToSpace(array[i]).includes(substring)) {
      return i; // Return the index if substring is found
    }
  }
  return -1; // Return -1 if substring is not found in any string
}

  
  return (
    <Grid sx={{ width: "100%" }}>
      <Grid container item justifyContent="center">
        <Typography variant="h4" sx={{ color: "black", padding: "0 0px 0 3px 0" }}>
          你的診斷是什麼?
        </Typography>
      </Grid>
      {questionSet.map((items, index) => (
        <Grid container item justifyContent="center" xs={12} key={index} sx={{ p: 1 }}>
          <SelectBaseButton
            onClick={() => handleOptionClick(items.value.toString())}
            sx={ buttonStyles[items.value.toString()] || defaultBtn}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexFlow: "row nowrap",
              }}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(items.description) }}
            />
          </SelectBaseButton>
        </Grid>
      ))}
      {data.length > 0  && (
        <Grid item xs={12} container alignItems="center" padding={'12px'}>
          <Grid item xs={6} container alignItems="center">
            <Typography variant="h6" sx={{ color: 'black', flex: '0 0 auto', paddingRight: '12px', fontSize: '14px' }}>
            診斷年份:<span style={{ color: 'red' }}>*</span>
            </Typography>
            <Select
              value={selectedOption1}
              onChange={(e) =>  handleInputChange(e, setSelectedOption1)}
              displayEmpty
              style={{ minWidth: '220px', marginRight: '5px', borderRadius: '10px', }} // Adjusted width and margin
            >
              <MenuItem value="">請選擇</MenuItem>
              {dropdownset && dropdownset.filter(item => item.category_id === 6).map((item, index) => (
                <MenuItem key={index} value={95+" "+item.option_name}>{item.option_name}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6} container alignItems="center">
            <Typography variant="h6" sx={{ color: 'black', flex: '0 0 auto', paddingRight: '12px', fontSize: '14px' }}>
            期數:<span style={{ color: 'red' }}>*</span>
            </Typography>
            <Select
              value={selectedOption2}
              onChange={(e) =>  handleInputChange(e, setSelectedOption2)}
              displayEmpty
              style={{ minWidth: '220px' , borderRadius: '10px'}} // Adjusted width
            >
              <MenuItem value="">請選擇</MenuItem>
              {dropdownset && dropdownset.filter(item => item.category_id === 7).map((item, index) => (
                <MenuItem key={index} value={96+" "+item.option_name}>{item.option_name}</MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        
      )}
    </Grid>
  );
};

export default DiagnosisForm;
