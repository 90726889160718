import * as React from "react";
import { useState, useEffect  } from "react";
import { Grid, Typography, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { DropdownSet } from "../../utils/types/services/dropdownSet";

interface PersonalInfoProps {
  data: string[];
  updateAnswer2: (data: string[]) => void;
  
  dropdownset?: DropdownSet[];
  onValidationChange?: (isValid: boolean) => void;
}

const PersonalInfoForm = (props: PersonalInfoProps) => {
  const [inputs, setInputs] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: ""
  });


  // Use useEffect to set input1 to props.data[0] when the component mounts
  useEffect(() => {
    const newInputs = {
      input1: props.data.find(str => str.includes("91")) || "",
      input2: props.data.find(str => str.includes("92")) || "",
      input3: props.data.find(str => str.includes("93")) || "",
      input4: props.data.find(str => str.includes("94")) || ""
    };
    setInputs(newInputs);
  }, [props.data]); // Run this effect whenever props.data changes

  
  useEffect(() => {
    const allDropdownsFilled = Object.values(inputs).every(value => value !== "");
    if (props.onValidationChange) {
      props.onValidationChange(allDropdownsFilled);
    }
  }, [inputs, props, props.onValidationChange]);
  
  const getSubstringUpToSpace = (mainString: string) => {
    const spaceIndex = mainString.indexOf(' ');
    return spaceIndex === -1 ? mainString : mainString.substring(0, spaceIndex);
  };
  
  const handleInputChange = (e: SelectChangeEvent<string>, inputKey: keyof typeof inputs) => { 
    const newValue = e.target.value;
    let newData = [...props.data];
    setInputs(prev => ({
      ...prev,
      [inputKey]: newValue
    }));   
    if(newValue===""){
      newData = newData.filter(item => !item.includes(getSubstringUpToSpace(inputs[inputKey])));
    } else {
      const prefix = inputKey === 'input1' ? '91' :
                     inputKey === 'input2' ? '92' :
                     inputKey === 'input3' ? '93' : '94';
                     
      const existingIndex = newData.findIndex(item => item.includes(prefix));
      if (existingIndex !== -1) {
        newData[existingIndex] = newValue;
      } else {
        newData.push(newValue);
      }
    }
    props.updateAnswer2(newData);
  };

  return (
    <Grid sx={{ width: "100%" }}>
      <Grid container item justifyContent="center">
        <div style={{ display: 'flex', alignItems: 'center', padding: '0px 30px 20px 0px' }}>
          <Typography
            variant="h4"
            sx={{ color: "black", padding: "0 0px 0 34px 0" }}
          >
            請輸入你的個人資訊:
          </Typography>
        </div>
      </Grid>

      <Grid container justifyContent="center">
        <form >
          <Grid container spacing={2}>
            {/* Input 1 */}
            <Grid item xs={6} container alignItems="center">
              <Typography variant="h6" sx={{ color: 'black', paddingRight: '20px', flex: '0 0 auto', fontSize: '24px' }}>
                年齡<span style={{ color: 'red' }}>*</span>
              </Typography>
              <Select
                labelId="dropdown1-label"
                id="dropdown1"
                value={inputs.input1}
                onChange={(e) => handleInputChange(e, "input1")}
                displayEmpty
                style={{ width: '180px', flex: '1', borderRadius: '12px' }} // Adjusted width and added border radius
              >
                <MenuItem value="">
                  <em>請選擇</em>
                </MenuItem>
                
                {props.dropdownset && props.dropdownset.filter(item => item.category_id === 2).map((item, index) => (
                  <MenuItem key={index} value={`91 ${item.option_name}`}>{item.option_name}</MenuItem>
                ))}
              </Select>
            </Grid>

            {/* Input 2 */}
            <Grid item xs={6} container alignItems="center">
              <Typography variant="h6" sx={{ color: 'black', paddingRight: '20px', flex: '0 0 auto', fontSize: '24px' }}>
                性別<span style={{ color: 'red' }}>*</span>
              </Typography>
              <Select
                labelId="dropdown2-label"
                id="dropdown2"
                value={inputs.input2}
                onChange={(e) => handleInputChange(e, "input2")}
                displayEmpty
                style={{ width: '180px', flex: '1', borderRadius: '12px' }} // Adjusted width and added border radius
              >
                <MenuItem value="">
                  <em>請選擇</em>
                </MenuItem>
                {props.dropdownset && props.dropdownset.filter(item => item.category_id === 3).map((item, index) => (
                  <MenuItem key={index} value={92+" "+item.option_name}>{item.option_name}</MenuItem>
                ))}
              </Select>
            </Grid>

            {/* Input 3 */}
            <Grid item xs={12} container alignItems="center">
              <Typography variant="h6" sx={{ color: 'black', paddingRight: '20px', flex: '0 0 auto' }}>
                工作狀況<span style={{ color: 'red' }}>*</span>
              </Typography>
              <Select
                labelId="dropdown3-label"
                id="dropdown3"
                value={inputs.input3}
                onChange={(e) => handleInputChange(e, "input3")}
                displayEmpty
                style={{ width: '400px', flex: '1', borderRadius: '12px' }} // Adjusted width and added border radius
              >
                <MenuItem value="">
                  <em>請選擇</em>
                </MenuItem>
                {props.dropdownset && props.dropdownset.filter(item => item.category_id === 4).map((item, index) => (
                  <MenuItem key={index} value={93+" "+item.option_name}>{item.option_name}</MenuItem>
                ))}
              </Select>
            </Grid>

            {/* Input 4 */}
            <Grid item xs={12} container alignItems="center">
              <Typography variant="h6" sx={{ color: 'black', paddingRight: '20px', flex: '0 0 auto' }}>
                你現時有沒有領取任何社區或經濟資助?<span style={{ color: 'red' }}>*</span>
              </Typography>
              <Select
                labelId="dropdown4-label"
                id="dropdown4"
                value={inputs.input4}
                onChange={(e) => handleInputChange(e, "input4")}
                displayEmpty
                style={{ width: '180px', flex: '1', borderRadius: '12px' }} // Adjusted width and added border radius
              >
                <MenuItem value="">
                  <em>請選擇</em>
                </MenuItem>
                {props.dropdownset && props.dropdownset.filter(item => item.category_id === 5).map((item, index) => (
                  <MenuItem key={index} value={94+" "+item.option_name}>{item.option_name}</MenuItem>
                ))}
              </Select>
            </Grid>
               
           
          </Grid>
        </form>
      </Grid>
      
    </Grid>
  );
  
};

export default PersonalInfoForm;
