import React, { useState, useEffect } from "react";
import { Grid, Typography, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { DropdownSet } from "../../utils/types/services/dropdownSet";

interface SourceofKnowingFormProps {
  data: string | null;
  updateAnswer10: (value: string | null) => void;
  dropdownset?: DropdownSet[];
  setDropdownValidity: (isValid: boolean) => void;
}

const SourceofKnowingForm = (props: SourceofKnowingFormProps) => {
  const { updateAnswer10, setDropdownValidity } = props;
  const [input1, setInput1] = useState<string | null>(null);
  


// Use useEffect to set input1 to props.data[0] when the component mounts
useEffect(() => {
  
  setInput1(props.data); // Set input1 to props.data[0] or an empty string if props.data[0] is undefined
  setDropdownValidity(props.data !== "" && props.data !== "請選擇" && props.data !== "Please select");  
}, [props.data, setDropdownValidity]); // Run this effect whenever props.data changes


  const handleSubmit = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    setInput1(selectedValue);
    if(selectedValue === ""){
    
      
      setInput1('');
      updateAnswer10('');

    }else{
      
      updateAnswer10(selectedValue);
    }

    
    setDropdownValidity(selectedValue !== "" && selectedValue !== "請選擇" && selectedValue !== "Please select");
   
   
   
    
  };

  return (
    <Grid sx={{ width: "100%" }}>
      <Grid container item justifyContent="center">
        <Typography variant="h4" sx={{ color: "black", padding: "0px 0 3px 0" }}>
          你從哪個途徑得知這個服務?
        </Typography>
      </Grid>
      <Grid container item justifyContent="center" style={{ paddingTop: "16px" }}>
        {/* Input 1 */}
        <Grid item xs={1} container alignItems="center" style={{ paddingRight: "6px" }}>
          <Typography variant="h6" sx={{ color: "black", flex: "0 0 auto", paddingRight: "12px", fontSize: "14px" }}>
            途徑:<span style={{ color: 'red' }}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={11} container alignItems="center" style={{ paddingLeft: "6px" }}>
          <Select
            id="dropdown10"
            value={input1 ?? ''}
            onChange={handleSubmit}
            displayEmpty
            style={{ 
             flex: "1",
             padding: "5px", 
             borderRadius: "5px",
             border: "1px solid rgb(0, 96, 140)", 
             outline: "none", color: "rgb(0, 96, 140)", 
             fontSize: "14px", 
             fontFamily: '"Roboto", "Helvetica", "Arial",sans-serif', 
             height: "40px" 
             }}
          >
            <MenuItem value="">請選擇</MenuItem>
            {props.dropdownset && props.dropdownset
              .filter(item => item.category_id === 10)
              .map((item, index) => (
                <MenuItem key={index} value={item.option_name}>{item.option_name}</MenuItem>
              ))}
          </Select>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SourceofKnowingForm;
