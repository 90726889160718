import {
  Grid,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
} from "@mui/material";
import { useState, useEffect } from "react";
import { DropdownSet } from "../../utils/types/services/dropdownSet";
import Logger from "../../utils/logger";

const NUMBER_3 = 3;
interface TreatementQuestionsProps {
  data: string[];
  updateAnswer6: (data: string[]) => void;
  dropdownset?: DropdownSet[];
  onValidationChange?: (isValid: boolean) => void;
}

const commonPaddingStyle = "0px 10px 0px 0";
const commonBorderStyle = "1px solid rgb(0, 96, 140)";
const commonColorStyle = "rgb(0, 96, 140)";
const commonFontStyle = '"Roboto", "Helvetica", "Arial",sans-serif';

const TreatementQuestionsForm = (props: TreatementQuestionsProps) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptions1a, setSelectedOptions1a] = useState("");
  const [selectedOptions2a, setSelectedOptions2a] = useState("");
  const [selectedOptions3a, setSelectedOptions3a] = useState("");

  const [showAdditionalInputs, setShowAdditionalInputs] = useState(false);

  const handleOptionChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string,
  ) => {
    if (newValue === "Yes") {
      props.data[0] = "101  Yes  input1  input2  input3";

      setSelectedOptions1a("");
      setSelectedOptions2a("");
      setSelectedOptions3a("");

      Logger.info(JSON.stringify(props.data));
      setSelectedOption(newValue);

      setShowAdditionalInputs(true);
    } else if (newValue === "No") {
      props.data[0] = "101  No";
      setShowAdditionalInputs(false);

      setSelectedOption(newValue);
      if (
        props.data.length === 4 &&
        props.data.hasOwnProperty(0) &&
        props.data.hasOwnProperty(1) &&
        props.data.hasOwnProperty(2) &&
        props.data.hasOwnProperty(NUMBER_3) &&
        !props.data.some(
          (str) =>
            str.split("  ").includes("input1") ||
            str.split("  ").includes("input2") ||
            str.split("  ").includes("input3") ||
            str.split("  ").includes("input4"),
        )
      )
        props.updateAnswer6(props.data);
    } else if (newValue === "Maybe") {
      props.data[0] = "101  Maybe";
      setShowAdditionalInputs(false);

      Logger.info(JSON.stringify(props.data));
      setSelectedOption(newValue);
      if (
        props.data.length === 4 &&
        props.data.hasOwnProperty(0) &&
        props.data.hasOwnProperty(1) &&
        props.data.hasOwnProperty(2) &&
        props.data.hasOwnProperty(NUMBER_3) &&
        !props.data.some(
          (str) =>
            str.split("  ").includes("input1") ||
            str.split("  ").includes("input2") ||
            str.split("  ").includes("input3") ||
            str.split("  ").includes("input4"),
        )
      )
        props.updateAnswer6(props.data);
    } else {
      return;
    }
  };
  const dropdownset = props.dropdownset;

  // Create constants for category IDs
  const CATEGORY_ID_1 = 9;
  const CATEGORY_ID_2 = 6;
  const CATEGORY_ID_3 = 1;

  //Field 2 options selection
  const [selectedOption2, setSelectedOption2] = useState("");
  const [selectedOptions1b, setSelectedOptions1b] = useState("");
  const [selectedOptions2b, setSelectedOptions2b] = useState("");
  const [selectedOptions3b, setSelectedOptions3b] = useState("");

  const [showAdditionalInputs2, setShowAdditionalInputs2] = useState(false);

  const handleOptionChange2 = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string,
  ) => {
    if (newValue === "Yes") {
      props.data[1] = "102  Yes  input1  input2  input3";
      setSelectedOptions1b("");
      setSelectedOptions2b("");
      setSelectedOptions3b("");

      Logger.info(JSON.stringify(props.data));
      setSelectedOption2(newValue);

      setShowAdditionalInputs2(true);
    } else if (newValue === "No") {
      props.data[1] = "102  No";
      setShowAdditionalInputs2(false);

      Logger.info(JSON.stringify(props.data));
      setSelectedOption2(newValue);
      if (
        props.data.length === 4 &&
        props.data.hasOwnProperty(0) &&
        props.data.hasOwnProperty(1) &&
        props.data.hasOwnProperty(2) &&
        props.data.hasOwnProperty(NUMBER_3) &&
        !props.data.some(
          (str) =>
            str.split("  ").includes("input1") ||
            str.split("  ").includes("input2") ||
            str.split("  ").includes("input3") ||
            str.split("  ").includes("input4"),
        )
      )
        props.updateAnswer6(props.data);
    } else if (newValue === "Maybe") {
      props.data[1] = "102  Maybe";
      setShowAdditionalInputs2(false);

      Logger.info(JSON.stringify(props.data));
      setSelectedOption2(newValue);
      if (
        props.data.length === 4 &&
        props.data.hasOwnProperty(0) &&
        props.data.hasOwnProperty(1) &&
        props.data.hasOwnProperty(2) &&
        props.data.hasOwnProperty(NUMBER_3) &&
        !props.data.some(
          (str) =>
            str.split("  ").includes("input1") ||
            str.split("  ").includes("input2") ||
            str.split("  ").includes("input3") ||
            str.split("  ").includes("input4"),
        )
      )
        props.updateAnswer6(props.data);
    } else {
      return;
    }
  };

  //Field 3 options selection
  const [selectedOption3, setSelectedOption3] = useState("");
  const [selectedOptions1c, setSelectedOptions1c] = useState("");
  const [selectedOptions2c, setSelectedOptions2c] = useState("");
  const [selectedOptions3c, setSelectedOptions3c] = useState("");

  const [showAdditionalInputs3, setShowAdditionalInputs3] = useState(false);

  const handleOptionChange3 = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string,
  ) => {
    if (newValue === "Yes") {
      props.data[2] = "103  Yes  input1  input2  input3";
      setSelectedOptions1c("");
      setSelectedOptions2c("");
      setSelectedOptions3c("");

      Logger.info(JSON.stringify(props.data));
      setSelectedOption3(newValue);

      setShowAdditionalInputs3(true);
    } else if (newValue === "No") {
      props.data[2] = "103  No";
      setShowAdditionalInputs3(false);

      Logger.info(JSON.stringify(props.data));
      setSelectedOption3(newValue);
      if (
        props.data.length === 4 &&
        props.data.hasOwnProperty(0) &&
        props.data.hasOwnProperty(1) &&
        props.data.hasOwnProperty(2) &&
        props.data.hasOwnProperty(NUMBER_3) &&
        !props.data.some(
          (str) =>
            str.split("  ").includes("input1") ||
            str.split("  ").includes("input2") ||
            str.split("  ").includes("input3") ||
            str.split("  ").includes("input4"),
        )
      )
        props.updateAnswer6(props.data);
    } else if (newValue === "Maybe") {
      props.data[2] = "103  Maybe";
      setShowAdditionalInputs3(false);

      Logger.info(JSON.stringify(props.data));
      setSelectedOption3(newValue);
      if (
        props.data.length === 4 &&
        props.data.hasOwnProperty(0) &&
        props.data.hasOwnProperty(1) &&
        props.data.hasOwnProperty(2) &&
        props.data.hasOwnProperty(NUMBER_3) &&
        !props.data.some(
          (str) =>
            str.split("  ").includes("input1") ||
            str.split("  ").includes("input2") ||
            str.split("  ").includes("input3") ||
            str.split("  ").includes("input4"),
        )
      )
        props.updateAnswer6(props.data);
    } else {
      return;
    }
  };

  //Field 4 options selection
  const [selectedOption4, setSelectedOption4] = useState("");
  const [selectedOptions1d, setSelectedOptions1d] = useState("");
  const [selectedOptions2d, setSelectedOptions2d] = useState("");
  const [selectedOptions3d, setSelectedOptions3d] = useState("");

  const [showAdditionalInputs4, setShowAdditionalInputs4] = useState(false);

  const handleOptionChange4 = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string,
  ) => {
    if (newValue === "Yes") {
      props.data[NUMBER_3] = "104  Yes  input1  input2  input3";
      setSelectedOptions1d("");
      setSelectedOptions2c("");
      setSelectedOptions3c("");

      setSelectedOption4(newValue);

      setShowAdditionalInputs4(true);
    } else if (newValue === "No") {
      props.data[NUMBER_3] = "104  No";
      setShowAdditionalInputs4(false);

      Logger.info(JSON.stringify(props.data));
      setSelectedOption4(newValue);
      // if(!props.data.some(element => element === '') )
      //  // props.updateAnswer6(props.update)
      if (
        props.data.length === 4 &&
        props.data.hasOwnProperty(0) &&
        props.data.hasOwnProperty(1) &&
        props.data.hasOwnProperty(2) &&
        props.data.hasOwnProperty(NUMBER_3) &&
        !props.data.some(
          (str) =>
            str.split("  ").includes("input1") ||
            str.split("  ").includes("input2") ||
            str.split("  ").includes("input3") ||
            str.split("  ").includes("input4"),
        )
      )
        props.updateAnswer6(props.data);
    } else if (newValue === "Maybe") {
      props.data[NUMBER_3] = "104  Maybe";
      setShowAdditionalInputs4(false);

      Logger.info(JSON.stringify(props.data));
      setSelectedOption4(newValue);
      if (
        props.data.length === 4 &&
        props.data.hasOwnProperty(0) &&
        props.data.hasOwnProperty(1) &&
        props.data.hasOwnProperty(2) &&
        props.data.hasOwnProperty(NUMBER_3) &&
        !props.data.some(
          (str) =>
            str.split("  ").includes("input1") ||
            str.split("  ").includes("input2") ||
            str.split("  ").includes("input3") ||
            str.split("  ").includes("input4"),
        )
      )
        props.updateAnswer6(props.data);
    } else {
      return;
    }
  };

  const handleOptionsChange = (
    event: SelectChangeEvent<string>,
    dropdownId: string,
  ) => {
    const selectedId = dropdownId;
    switch (selectedId) {
      case "dropdown1a":
        setSelectedOptions1a(event.target.value as string);
        const substring1a = props.data[0].split("  ");
        props.data[0] =
          ((substring1a[0] +
            "  " +
            substring1a[1] +
            "  " +
            event.target.value) as string) +
          "  " +
          substring1a[NUMBER_3] +
          "  " +
          substring1a[4];

        if (
          props.data.length === 4 &&
          props.data.hasOwnProperty(0) &&
          props.data.hasOwnProperty(1) &&
          props.data.hasOwnProperty(2) &&
          props.data.hasOwnProperty(NUMBER_3) &&
          !props.data.some(
            (str) =>
              str.split("  ").includes("input1") ||
              str.split("  ").includes("input2") ||
              str.split("  ").includes("input3") ||
              str.split("  ").includes("input4"),
          )
        )
          props.updateAnswer6(props.data);
        break;

      case "dropdown2a":
        setSelectedOptions2a(event.target.value as string);
        const substring2a = props.data[0].split("  ");
        props.data[0] =
          ((substring2a[0] +
            "  " +
            substring2a[1] +
            "  " +
            substring2a[2] +
            "  " +
            event.target.value) as string) +
          "  " +
          substring2a[4];

        if (
          props.data.length === 4 &&
          props.data.hasOwnProperty(0) &&
          props.data.hasOwnProperty(1) &&
          props.data.hasOwnProperty(2) &&
          props.data.hasOwnProperty(NUMBER_3) &&
          !props.data.some(
            (str) =>
              str.split("  ").includes("input1") ||
              str.split("  ").includes("input2") ||
              str.split("  ").includes("input3") ||
              str.split("  ").includes("input4"),
          )
        )
          props.updateAnswer6(props.data);
        break;
      case "dropdown3a":
        setSelectedOptions3a(event.target.value as string);
        const substring3a = props.data[0].split("  ");
        props.data[0] = (substring3a[0] +
          "  " +
          substring3a[1] +
          "  " +
          substring3a[2] +
          "  " +
          substring3a[NUMBER_3] +
          "  " +
          event.target.value) as string;

        if (
          props.data.length === 4 &&
          props.data.hasOwnProperty(0) &&
          props.data.hasOwnProperty(1) &&
          props.data.hasOwnProperty(2) &&
          props.data.hasOwnProperty(NUMBER_3) &&
          !props.data.some(
            (str) =>
              str.split("  ").includes("input1") ||
              str.split("  ").includes("input2") ||
              str.split("  ").includes("input3") ||
              str.split("  ").includes("input4"),
          )
        )
          props.updateAnswer6(props.data);
        break;

      case "dropdown1b":
        setSelectedOptions1b(event.target.value as string);
        const substring1b = props.data[1].split("  ");
        props.data[1] =
          ((substring1b[0] +
            "  " +
            substring1b[1] +
            "  " +
            event.target.value) as string) +
          "  " +
          substring1b[NUMBER_3] +
          "  " +
          substring1b[4];
        if (
          props.data.length === 4 &&
          props.data.hasOwnProperty(0) &&
          props.data.hasOwnProperty(1) &&
          props.data.hasOwnProperty(2) &&
          props.data.hasOwnProperty(NUMBER_3) &&
          !props.data.some(
            (str) =>
              str.split("  ").includes("input1") ||
              str.split("  ").includes("input2") ||
              str.split("  ").includes("input3") ||
              str.split("  ").includes("input4"),
          )
        )
          props.updateAnswer6(props.data);

        break;
      case "dropdown2b":
        setSelectedOptions2b(event.target.value as string);
        const substring2b = props.data[1].split("  ");
        props.data[1] =
          ((substring2b[0] +
            "  " +
            substring2b[1] +
            "  " +
            substring2b[2] +
            "  " +
            event.target.value) as string) +
          "  " +
          substring2b[4];
        if (
          props.data.length === 4 &&
          props.data.hasOwnProperty(0) &&
          props.data.hasOwnProperty(1) &&
          props.data.hasOwnProperty(2) &&
          props.data.hasOwnProperty(NUMBER_3) &&
          !props.data.some(
            (str) =>
              str.split("  ").includes("input1") ||
              str.split("  ").includes("input2") ||
              str.split("  ").includes("input3") ||
              str.split("  ").includes("input4"),
          )
        )
          props.updateAnswer6(props.data);

        break;
      case "dropdown3b":
        setSelectedOptions3b(event.target.value as string);
        const substring3b = props.data[1].split("  ");
        props.data[1] = (substring3b[0] +
          "  " +
          substring3b[1] +
          "  " +
          substring3b[2] +
          "  " +
          substring3b[NUMBER_3] +
          "  " +
          event.target.value) as string;
        if (
          props.data.length === 4 &&
          props.data.hasOwnProperty(0) &&
          props.data.hasOwnProperty(1) &&
          props.data.hasOwnProperty(2) &&
          props.data.hasOwnProperty(NUMBER_3) &&
          !props.data.some(
            (str) =>
              str.split("  ").includes("input1") ||
              str.split("  ").includes("input2") ||
              str.split("  ").includes("input3") ||
              str.split("  ").includes("input4"),
          )
        )
          props.updateAnswer6(props.data);
        break;
      case "dropdown1c":
        setSelectedOptions1c(event.target.value as string);
        const substring1c = props.data[2].split("  ");
        props.data[2] =
          ((substring1c[0] +
            "  " +
            substring1c[1] +
            "  " +
            event.target.value) as string) +
          "  " +
          substring1c[NUMBER_3] +
          "  " +
          substring1c[4];
        if (
          props.data.length === 4 &&
          props.data.hasOwnProperty(0) &&
          props.data.hasOwnProperty(1) &&
          props.data.hasOwnProperty(2) &&
          props.data.hasOwnProperty(NUMBER_3) &&
          !props.data.some(
            (str) =>
              str.split("  ").includes("input1") ||
              str.split("  ").includes("input2") ||
              str.split("  ").includes("input3") ||
              str.split("  ").includes("input4"),
          )
        )
          props.updateAnswer6(props.data);
        break;
      case "dropdown2c":
        setSelectedOptions2c(event.target.value as string);
        const substring2c = props.data[2].split("  ");
        props.data[2] =
          ((substring2c[0] +
            "  " +
            substring2c[1] +
            "  " +
            substring2c[2] +
            "  " +
            event.target.value) as string) +
          "  " +
          substring2c[4];
        if (
          props.data.length === 4 &&
          props.data.hasOwnProperty(0) &&
          props.data.hasOwnProperty(1) &&
          props.data.hasOwnProperty(2) &&
          props.data.hasOwnProperty(NUMBER_3) &&
          !props.data.some(
            (str) =>
              str.split("  ").includes("input1") ||
              str.split("  ").includes("input2") ||
              str.split("  ").includes("input3") ||
              str.split("  ").includes("input4"),
          )
        )
          props.updateAnswer6(props.data);
        break;
      case "dropdown3c":
        setSelectedOptions3c(event.target.value as string);
        const substring3c = props.data[2].split("  ");
        props.data[2] = (substring3c[0] +
          "  " +
          substring3c[1] +
          "  " +
          substring3c[2] +
          "  " +
          substring3c[NUMBER_3] +
          "  " +
          event.target.value) as string;
        if (
          props.data.length === 4 &&
          props.data.hasOwnProperty(0) &&
          props.data.hasOwnProperty(1) &&
          props.data.hasOwnProperty(2) &&
          props.data.hasOwnProperty(NUMBER_3) &&
          !props.data.some(
            (str) =>
              str.split("  ").includes("input1") ||
              str.split("  ").includes("input2") ||
              str.split("  ").includes("input3") ||
              str.split("  ").includes("input4"),
          )
        )
          props.updateAnswer6(props.data);
        break;
      case "dropdown1d":
        setSelectedOptions1d(event.target.value as string);
        const substring1d = props.data[NUMBER_3].split("  ");
        props.data[NUMBER_3] =
          ((substring1d[0] +
            "  " +
            substring1d[1] +
            "  " +
            event.target.value) as string) +
          "  " +
          substring1d[NUMBER_3] +
          "  " +
          substring1d[4];
        if (
          props.data.length === 4 &&
          props.data.hasOwnProperty(0) &&
          props.data.hasOwnProperty(1) &&
          props.data.hasOwnProperty(2) &&
          props.data.hasOwnProperty(NUMBER_3) &&
          !props.data.some(
            (str) =>
              str.split("  ").includes("input1") ||
              str.split("  ").includes("input2") ||
              str.split("  ").includes("input3") ||
              str.split("  ").includes("input4"),
          )
        )
          props.updateAnswer6(props.data);
        break;
      case "dropdown2d":
        setSelectedOptions2d(event.target.value as string);
        const substring2d = props.data[NUMBER_3].split("  ");
        props.data[NUMBER_3] =
          ((substring2d[0] +
            "  " +
            substring2d[1] +
            "  " +
            substring2d[2] +
            "  " +
            event.target.value) as string) +
          "  " +
          substring2d[4];
        if (
          props.data.length === 4 &&
          props.data.hasOwnProperty(0) &&
          props.data.hasOwnProperty(1) &&
          props.data.hasOwnProperty(2) &&
          props.data.hasOwnProperty(NUMBER_3) &&
          !props.data.some(
            (str) =>
              str.split("  ").includes("input1") ||
              str.split("  ").includes("input2") ||
              str.split("  ").includes("input3") ||
              str.split("  ").includes("input4"),
          )
        )
          props.updateAnswer6(props.data);
        break;
      case "dropdown3d":
        setSelectedOptions3d(event.target.value as string);
        const substring3d = props.data[NUMBER_3].split("  ");
        props.data[NUMBER_3] = (substring3d[0] +
          "  " +
          substring3d[1] +
          "  " +
          substring3d[2] +
          "  " +
          substring3d[NUMBER_3] +
          "  " +
          event.target.value) as string;
        if (
          props.data.length === 4 &&
          props.data.hasOwnProperty(0) &&
          props.data.hasOwnProperty(1) &&
          props.data.hasOwnProperty(2) &&
          props.data.hasOwnProperty(NUMBER_3) &&
          !props.data.some(
            (str) =>
              str.split("  ").includes("input1") ||
              str.split("  ").includes("input2") ||
              str.split("  ").includes("input3") ||
              str.split("  ").includes("input4"),
          )
        )
          props.updateAnswer6(props.data);
        break;
      default:
        break;
    }
  };

  // const {
  //   data,
  //   onValidationChange,
  //   // Destructure other needed props here if any
  // } = props;

  // Use useEffect to set input1 to props.data[0] when the component mounts
  useEffect(() => {
    Logger.info("hello");

    const placeholders = ["month", "月份", "years", "年份", "Please select", "請選擇"];
    const selectedOptions = [
      selectedOption,
      selectedOption2,
      selectedOption,
      selectedOption2,
      selectedOption3,
      selectedOption4,
      selectedOptions1a,
      selectedOptions2a,
      selectedOptions3a,
      selectedOptions1b,
      selectedOptions2b,
      selectedOptions3b,
      selectedOptions1c,
      selectedOptions2c,
      selectedOptions3c,
      selectedOptions1d,
      selectedOptions2d,
      selectedOptions3d
    ];

    const hasSelectedYesButNoDropdowns = (
      (selectedOption === "Yes" && (
        selectedOptions1a === "" ||
        selectedOptions1a === "月份" ||
        selectedOptions1a === "Please select" ||
        selectedOptions1a === "請選擇"
      )) ||
      (selectedOption2 === "Yes" && (
        selectedOptions1b === "" ||
        selectedOptions1b === "月份" ||
        selectedOptions1b === "Please select" ||
        selectedOptions1b === "請選擇"
      )) ||
      (selectedOption3 === "Yes" && (
        selectedOptions1c === "" ||
        selectedOptions1c === "月份" ||
        selectedOptions1c === "Please select" ||
        selectedOptions1c === "請選擇"
      )) ||
      (selectedOption4 === "Yes" && (
        selectedOptions1d === "" ||
        selectedOptions1d === "月份" ||
        selectedOptions1d === "Please select" ||
        selectedOptions1d === "請選擇"
      )) ||
      (selectedOption === "Yes" && (
        selectedOptions2a === "" ||
        selectedOptions2a === "年份" ||
        selectedOptions2a === "Please select" ||
        selectedOptions2a === "請選擇"
      )) ||
      (selectedOption2 === "Yes" && (
        selectedOptions2b === "" ||
        selectedOptions2b === "年份" ||
        selectedOptions2b === "Please select" ||
        selectedOptions2b === "請選擇"
      )) ||
      (selectedOption3 === "Yes" && (
        selectedOptions2c === "" ||
        selectedOptions2c === "年份" ||
        selectedOptions2c === "Please select" ||
        selectedOptions2c === "請選擇"
      )) ||
      (selectedOption4 === "Yes" && (
        selectedOptions2d === "" ||
        selectedOptions2d === "年份" ||
        selectedOptions2d === "Please select" ||
        selectedOptions2d === "請選擇"
      )) ||
      (selectedOption === "Yes" && (
        selectedOptions3a === "" ||
        selectedOptions3a === "Please select" ||
        selectedOptions3a === "請選擇"
      )) ||
      (selectedOption2 === "Yes" && (
        selectedOptions3b === "" ||
        selectedOptions3b === "Please select" ||
        selectedOptions3b === "請選擇"
      )) ||
      (selectedOption3 === "Yes" && (
        selectedOptions3c === "" ||
        selectedOptions3c === "Please select" ||
        selectedOptions3c === "請選擇"
      )) ||
      (selectedOption4 === "Yes" && (
        selectedOptions3d === "" ||
        selectedOptions3d === "Please select" ||
        selectedOptions3d === "請選擇"
      ))
    );

    const isValid =
    !hasSelectedYesButNoDropdowns &&
    (
      (selectedOption !== "Yes" ||
       (selectedOptions1a !== "" &&
        selectedOptions1a !== "月份" &&
        selectedOptions1a !== "Please select" &&
        selectedOptions1a !== "請選擇")
      ) &&
      (selectedOption2 !== "Yes" ||
       (selectedOptions1b !== "" &&
        selectedOptions1b !== "月份" &&
        selectedOptions1b !== "Please select" &&
        selectedOptions1b !== "請選擇")
      ) &&
      (selectedOption3 !== "Yes" ||
       (selectedOptions1c !== "" &&
        selectedOptions1c !== "月份" &&
        selectedOptions1c !== "Please select" &&
        selectedOptions1c !== "請選擇")
      ) &&
      (selectedOption4 !== "Yes" ||
        (selectedOptions1d !== "" &&
         selectedOptions1d !== "月份" &&
         selectedOptions1d !== "Please select" &&
         selectedOptions1d !== "請選擇")
       )
     );
 

    if (props.onValidationChange) {
      props.onValidationChange(isValid);
    }



    if (props.data) {
      Logger.info(JSON.stringify(props.data));
      const oldValue = [];
      oldValue[0] = props.data[0];
      oldValue[1] = props.data[1];
      oldValue[2] = props.data[2];
      oldValue[NUMBER_3] = props.data[NUMBER_3];

    }

    if (props.data[0]) {
      setSelectedOption(props.data[0].split("  ")[1].toString() || "");
      setShowAdditionalInputs(
        props.data[0].split("  ")[1] === "Yes" ? true : false,
      );
    } // Set input1

    if (props.data[1]) {
      setSelectedOption2(props.data[1].split("  ")[1].toString() || "");
      setShowAdditionalInputs2(
        props.data[1].split("  ")[1] === "Yes" ? true : false,
      );
    }

    if (props.data[2]) {
      setSelectedOption3(props.data[2].split("  ")[1].toString() || "");
      setShowAdditionalInputs3(
        props.data[2].split("  ")[1] === "Yes" ? true : false,
      );
    }

    if (props.data[NUMBER_3]) {
      setSelectedOption4(props.data[NUMBER_3].split("  ")[1].toString() || "");
      setShowAdditionalInputs4(
        props.data[NUMBER_3].split("  ")[1] === "Yes" ? true : false,
      );
    }

    if (props.data[0]) {
      if (
        props.data[0].split("  ")[2] !== "input1" &&
        props.data[0].split("  ")[2]
      )
        setSelectedOptions1a(props.data[0].split("  ")[2]);

      if (
        props.data[0].split("  ")[NUMBER_3] !== "input2" &&
        props.data[0].split("  ")[NUMBER_3]
      )
        setSelectedOptions2a(props.data[0].split("  ")[NUMBER_3]);

      if (
        props.data[0].split("  ")[4] !== "input3" &&
        props.data[0].split("  ")[4]
      )
        setSelectedOptions3a(props.data[0].split("  ")[4]);
    }

    if (props.data[1]) {
      if (
        props.data[1].split("  ")[2] !== "input1" &&
        props.data[1].split("  ")[2]
      )
        setSelectedOptions1b(props.data[1].split("  ")[2]);

      if (
        props.data[1].split("  ")[NUMBER_3] !== "input2" &&
        props.data[1].split("  ")[NUMBER_3]
      )
        setSelectedOptions2b(props.data[1].split("  ")[NUMBER_3]);

      if (
        props.data[1].split("  ")[4] !== "input3" &&
        props.data[1].split("  ")[4]
      )
        setSelectedOptions3b(props.data[1].split("  ")[4]);
    }

    if (props.data[2]) {
      if (
        props.data[2].split("  ")[2] !== "input1" &&
        props.data[2].split("  ")[2]
      )
        setSelectedOptions1c(props.data[2].split("  ")[2]);

      if (
        props.data[2].split("  ")[NUMBER_3] !== "input2" &&
        props.data[2].split("  ")[NUMBER_3]
      )
        setSelectedOptions2c(props.data[2].split("  ")[NUMBER_3]);

      if (
        props.data[2].split("  ")[4] !== "input3" &&
        props.data[2].split("  ")[4]
      )
        setSelectedOptions3c(props.data[2].split("  ")[4]);
    }

    if (props.data[NUMBER_3]) {
      if (
        props.data[NUMBER_3].split("  ")[2] !== "input1" &&
        props.data[NUMBER_3].split("  ")[2]
      )
        setSelectedOptions1d(props.data[NUMBER_3].split("  ")[2]);

      if (
        props.data[NUMBER_3].split("  ")[NUMBER_3] !== "input2" &&
        props.data[NUMBER_3].split("  ")[NUMBER_3]
      )
        setSelectedOptions2d(props.data[NUMBER_3].split("  ")[NUMBER_3]);

      if (
        props.data[NUMBER_3].split("  ")[4] !== "input3" &&
        props.data[NUMBER_3].split("  ")[4]
      )
        setSelectedOptions3d(props.data[NUMBER_3].split("  ")[4]);
    }
  }, [props.data, selectedOption, selectedOption2, selectedOption3, selectedOption4, selectedOptions1a, selectedOptions1b, selectedOptions1c, selectedOptions1d, selectedOptions2a, selectedOptions2b, selectedOptions2c, selectedOptions2d, selectedOptions3a, selectedOptions3b, selectedOptions3c, selectedOptions3d, props.onValidationChange, props]); // Run this effect whenever props.data changes

  return (
    <Grid sx={{ width: "100%" }}>
      <Grid container justifyContent="center">
        <Grid container spacing={2}>
          <Grid item xs={6} container alignItems="center">
            <Typography
              variant="h6"
              sx={{ color: "black", padding: commonPaddingStyle }}
            >
              有沒有曾經或現正接受手術?<span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={6} container alignItems="center">
            <ToggleButtonGroup
              value={selectedOption}
              exclusive
              onChange={handleOptionChange}
              aria-label="gender"
              size="large"
              // Adjust size as needed
            >
              <ToggleButton
                value="Yes"
                sx={{
                  flex: "1",
                  marginRight: "10px",
                  height: "40px",
                  padding: "center",
                  borderRadius: "50px",
                  border: commonBorderStyle,
                }}
              >
                <Typography variant="h6" sx={{ color: commonColorStyle }}>
                  有
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>

            <ToggleButtonGroup
              value={selectedOption}
              exclusive
              onChange={handleOptionChange}
              aria-label="gender"
              size="large"
              // Adjust size as needed
            >
              <ToggleButton
                value="No"
                sx={{
                  flex: "1",
                  marginRight: "10px",
                  height: "40px",
                  borderRadius: "50px",
                  border: commonBorderStyle,
                }}
              >
                <Typography variant="h6" sx={{ color: commonColorStyle }}>
                  沒有
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>

            {/* <ToggleButtonGroup
    value={selectedOption}
    exclusive
    onChange={handleOptionChange}
    aria-label="gender"
    size="large" 
    // Adjust size as needed
  >

    <ToggleButton value="Maybe"  sx={{ flex:'1',  height: '40px', padding:'center', borderRadius: "50px", border: commonBorderStyle}} >
      <Typography variant="h6" sx={{ color: commonColorStyle }}>不知道</Typography>
    </ToggleButton>
    </ToggleButtonGroup> */}
          </Grid>
        </Grid>

        {showAdditionalInputs && (
          <Grid item xs={12} container alignItems="center" paddingTop={"12px"}>
            <Grid item xs={6} container alignItems="center">
              <Grid
                item
                xs={2}
                container
                alignItems="center"
                style={{ paddingRight: "6px" }}
              >
                <Typography
                  variant="h6"
                  sx={{ color: "black", flex: "0 0 auto", fontSize: "14px" }}
                >
                  日期:<span style={{ color: 'red' }}>*</span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={5}
                container
                alignItems="center"
                style={{ paddingLeft: "6px" }}
              >
                <Select
                  id="dropdown1a"
                  value={selectedOptions1a}
                  onChange={(event) => handleOptionsChange(event, "dropdown1a")}
                  displayEmpty
                  style={{
                    flex: "1",
                    padding: "5px",
                    borderRadius: "5px",
                    border: commonBorderStyle,
                    outline: "none",
                    color: commonColorStyle,
                    fontSize: "14px",
                    fontFamily: commonFontStyle,
                    height: "40px",
                  }}
                >
                  <MenuItem value="">月份</MenuItem>
                  {dropdownset &&
                    dropdownset
                      .filter((item) => item.category_id === CATEGORY_ID_1) // Filter based on category ID
                      .map((item, index) => (
                        <MenuItem key={index} value={item.option_name}>
                          {item.option_name}
                        </MenuItem>
                      ))}
                </Select>
              </Grid>
              <Grid
                item
                xs={5}
                container
                alignItems="center"
                style={{ paddingRight: "6px", paddingLeft: "5px" }}
              >
                <Select
                  id="dropdown2a"
                  value={selectedOptions2a}
                  onChange={(event) => handleOptionsChange(event, "dropdown2a")}
                  displayEmpty
                  style={{
                    flex: "1",
                    padding: "5px",
                    borderRadius: "5px",
                    border: commonBorderStyle,
                    outline: "none",
                    color: commonColorStyle,
                    fontSize: "14px",
                    fontFamily: commonFontStyle,
                    height: "40px",
                  }}
                >
                  <MenuItem value="">年份</MenuItem>
                  {dropdownset &&
                    dropdownset
                      .filter((item) => item.category_id === CATEGORY_ID_2) // Filter based on category ID
                      .map((item, index) => (
                        <MenuItem key={index} value={item.option_name}>
                          {item.option_name}
                        </MenuItem>
                      ))}
                </Select>
              </Grid>
            </Grid>

            <Grid
              item
              xs={6}
              container
              alignItems="center"
              style={{ paddingLeft: "10px" }}
            >
              <Grid
                item
                xs={2}
                container
                alignItems="center"
                style={{ paddingRight: "6px" }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "black",
                    flex: "0 0 auto",
                    paddingRight: "12px",
                    fontSize: "14px",
                  }}
                >
                  地點:<span style={{ color: 'red' }}>*</span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={10}
                container
                alignItems="center"
                style={{ paddingLeft: "6px" }}
              >
                <Select
                  id="dropdown3a"
                  value={selectedOptions3a}
                  onChange={(event) => handleOptionsChange(event, "dropdown3a")}
                  displayEmpty
                  style={{
                    flex: "1",
                    padding: "5px",
                    borderRadius: "5px",
                    border: commonBorderStyle,
                    outline: "none",
                    color: commonColorStyle,
                    fontSize: "14px",
                    fontFamily: commonFontStyle,
                    height: "40px",
                  }}
                >
                  <MenuItem value="">請選擇</MenuItem>
                  {dropdownset &&
                    dropdownset
                      .filter((item) => item.category_id === CATEGORY_ID_3) // Filter based on category ID
                      .map((item, index) => (
                        <MenuItem key={index} value={item.option_name}>
                          {item.option_name}
                        </MenuItem>
                      ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Divider variant="middle" style={{ margin: "16px 0" }} />

      {/*Field 2*/}
      <Grid container justifyContent="center">
        <Grid container spacing={2}>
          <Grid item xs={6} container alignItems="center">
            <Typography
              variant="h6"
              sx={{ color: "black", padding: commonPaddingStyle }}
            >
              有沒有曾經或現正接受化療?<span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={6} container alignItems="center">
            <ToggleButtonGroup
              value={selectedOption2}
              exclusive
              onChange={handleOptionChange2}
              aria-label="gender"
              size="large"
              // Adjust size as needed
            >
              <ToggleButton
                value="Yes"
                sx={{
                  flex: "1",
                  marginRight: "10px",
                  height: "40px",
                  padding: "center",
                  borderRadius: "50px",
                  border: commonBorderStyle,
                }}
              >
                <Typography variant="h6" sx={{ color: commonColorStyle }}>
                  有
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>

            <ToggleButtonGroup
              value={selectedOption2}
              exclusive
              onChange={handleOptionChange2}
              aria-label="gender"
              size="large"
            >
              <ToggleButton
                value="No"
                sx={{
                  flex: "1",
                  marginRight: "10px",
                  height: "40px",
                  borderRadius: "50px",
                  border: commonBorderStyle,
                }}
              >
                <Typography variant="h6" sx={{ color: commonColorStyle }}>
                  沒有
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>

            <ToggleButtonGroup
              value={selectedOption2}
              exclusive
              onChange={handleOptionChange2}
              aria-label="gender"
              size="large"
            >
              <ToggleButton
                value="Maybe"
                sx={{
                  flex: "1",
                  height: "40px",
                  padding: "center",
                  borderRadius: "50px",
                  border: commonBorderStyle,
                }}
              >
                <Typography variant="h6" sx={{ color: commonColorStyle }}>
                  不知道
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>

        {showAdditionalInputs2 && (
          <Grid item xs={12} container alignItems="center" paddingTop={"12px"}>
            <Grid item xs={6} container alignItems="center">
              <Grid
                item
                xs={2}
                container
                alignItems="center"
                style={{ paddingRight: "6px" }}
              >
                <Typography
                  variant="h6"
                  sx={{ color: "black", flex: "0 0 auto", fontSize: "14px" }}
                >
                  日期:<span style={{ color: 'red' }}>*</span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={5}
                container
                alignItems="center"
                style={{ paddingLeft: "6px" }}
              >
                <Select
                  id="dropdown1b"
                  value={selectedOptions1b}
                  onChange={(event) => handleOptionsChange(event, "dropdown1b")}
                  displayEmpty
                  style={{
                    flex: "1",
                    padding: "5px",
                    borderRadius: "5px",
                    border: commonBorderStyle,
                    outline: "none",
                    color: commonColorStyle,
                    fontSize: "14px",
                    fontFamily: commonFontStyle,
                    height: "40px",
                  }}
                >
                  <MenuItem value="">月份</MenuItem>
                  {dropdownset &&
                    dropdownset
                      .filter((item) => item.category_id === CATEGORY_ID_1) // Filter based on category ID
                      .map((item, index) => (
                        <MenuItem key={index} value={item.option_name}>
                          {item.option_name}
                        </MenuItem>
                      ))}
                </Select>
              </Grid>
              <Grid
                item
                xs={5}
                container
                alignItems="center"
                style={{ paddingRight: "6px", paddingLeft: "5px" }}
              >
                <Select
                  id="dropdown2b"
                  value={selectedOptions2b}
                  onChange={(event) => handleOptionsChange(event, "dropdown2b")}
                  displayEmpty
                  style={{
                    flex: "1",
                    padding: "5px",
                    borderRadius: "5px",
                    border: commonBorderStyle,
                    outline: "none",
                    color: commonColorStyle,
                    fontSize: "14px",
                    fontFamily: commonFontStyle,
                    height: "40px",
                  }}
                >
                  <MenuItem value="">年份</MenuItem>
                  {dropdownset &&
                    dropdownset
                      .filter((item) => item.category_id === CATEGORY_ID_2) // Filter based on category ID
                      .map((item, index) => (
                        <MenuItem key={index} value={item.option_name}>
                          {item.option_name}
                        </MenuItem>
                      ))}
                </Select>
              </Grid>
            </Grid>

            <Grid
              item
              xs={6}
              container
              alignItems="center"
              style={{ paddingLeft: "10px" }}
            >
              <Grid
                item
                xs={2}
                container
                alignItems="center"
                style={{ paddingRight: "6px" }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "black",
                    flex: "0 0 auto",
                    paddingRight: "12px",
                    fontSize: "14px",
                  }}
                >
                  地點:<span style={{ color: 'red' }}>*</span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={10}
                container
                alignItems="center"
                style={{ paddingLeft: "6px" }}
              >
                <Select
                  id="dropdown3b"
                  value={selectedOptions3b}
                  onChange={(event) => handleOptionsChange(event, "dropdown3b")}
                  displayEmpty
                  style={{
                    flex: "1",
                    padding: "5px",
                    borderRadius: "5px",
                    border: commonBorderStyle,
                    outline: "none",
                    color: commonColorStyle,
                    fontSize: "14px",
                    fontFamily: commonFontStyle,
                    height: "40px",
                  }}
                >
                  <MenuItem value="">請選擇</MenuItem>
                  {dropdownset &&
                    dropdownset
                      .filter((item) => item.category_id === CATEGORY_ID_3) // Filter based on category ID
                      .map((item, index) => (
                        <MenuItem key={index} value={item.option_name}>
                          {item.option_name}
                        </MenuItem>
                      ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      {/* Divider */}
      <Divider variant="middle" style={{ margin: "16px 0" }} />

      {/*Field 3*/}
      <Grid container justifyContent="center">
        <Grid container spacing={2}>
          <Grid item xs={6} container alignItems="center">
            <Typography
              variant="h6"
              sx={{ color: "black", padding: commonPaddingStyle }}
            >
              有沒有曾經或現正接受免疫治療?<span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={6} container alignItems="center">
            <ToggleButtonGroup
              value={selectedOption3}
              exclusive
              onChange={handleOptionChange3}
              aria-label="gender"
              size="large"
              // Adjust size as needed
            >
              <ToggleButton
                value="Yes"
                sx={{
                  flex: "1",
                  marginRight: "10px",
                  height: "40px",
                  padding: "center",
                  borderRadius: "50px",
                  border: commonBorderStyle,
                }}
              >
                <Typography variant="h6" sx={{ color: commonColorStyle }}>
                  有
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>

            <ToggleButtonGroup
              value={selectedOption3}
              exclusive
              onChange={handleOptionChange3}
              aria-label="gender"
              size="large"
              // Adjust size as needed
            >
              <ToggleButton
                value="No"
                sx={{
                  flex: "1",
                  marginRight: "10px",
                  height: "40px",
                  borderRadius: "50px",
                  border: commonBorderStyle,
                }}
              >
                <Typography variant="h6" sx={{ color: commonColorStyle }}>
                  沒有
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>

            <ToggleButtonGroup
              value={selectedOption3}
              exclusive
              onChange={handleOptionChange3}
              aria-label="gender"
              size="large"
              // Adjust size as needed
            >
              
              <ToggleButton
                value="Maybe"
                sx={{
                  flex: "1",
                  height: "40px",
                  padding: "center",
                  borderRadius: "50px",
                  border: commonBorderStyle,
                }}
              >
                <Typography variant="h6" sx={{ color: commonColorStyle }}>
                  不知道
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>

        {showAdditionalInputs3 && (
          <Grid item xs={12} container alignItems="center" paddingTop={"12px"}>
            <Grid item xs={6} container alignItems="center">
              <Grid
                item
                xs={2}
                container
                alignItems="center"
                style={{ paddingRight: "6px" }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "black",
                    flex: "0 0 auto",
                    paddingRight: "12px",
                    fontSize: "14px",
                  }}
                >
                  日期:<span style={{ color: 'red' }}>*</span>
                </Typography>
                </Grid>
                <Grid item xs={5} container alignItems="center" style={{ paddingLeft: "6px" }}> 
                <Select
                  id="dropdown1c"
                  value={selectedOptions1c}
                  onChange={(event) => handleOptionsChange(event, "dropdown1c")}
                  displayEmpty
                  style={{
                    flex: "1",
                    padding: "5px",
                    borderRadius: "5px",
                    border: commonBorderStyle,
                    outline: "none",
                    color: commonColorStyle,
                    fontSize: "14px",
                    fontFamily: commonFontStyle,
                    height: "40px",
                  }}
                >
                  <MenuItem value="">月份</MenuItem>
                  {dropdownset &&
                    dropdownset
                      .filter((item) => item.category_id === CATEGORY_ID_1) // Filter based on category ID
                      .map((item, index) => (
                        <MenuItem key={index} value={item.option_name}>
                          {item.option_name}
                        </MenuItem>
                      ))}
                </Select>
              </Grid>
              <Grid
                item
                xs={5}
                container
                alignItems="center"
                style={{ paddingLeft: "5px" }}
              >
                <Select
                  id="dropdown2c"
                  value={selectedOptions2c}
                  onChange={(event) => handleOptionsChange(event, "dropdown2c")}
                  displayEmpty
                  style={{
                    flex: "1",
                    padding: "5px",
                    borderRadius: "5px",
                    border: commonBorderStyle,
                    outline: "none",
                    color: commonColorStyle,
                    fontSize: "14px",
                    fontFamily: commonFontStyle,
                    height: "40px",
                  }}
                >
                  <MenuItem value="">年份</MenuItem>
                  {dropdownset &&
                    dropdownset
                      .filter((item) => item.category_id === CATEGORY_ID_2) // Filter based on category ID
                      .map((item, index) => (
                        <MenuItem key={index} value={item.option_name}>
                          {item.option_name}
                        </MenuItem>
                      ))}
                </Select>
              </Grid>
            </Grid>

            <Grid
              item
              xs={6}
              container
              alignItems="center"
              //style={{ paddingLeft: "10px" }}
            >
              <Grid item xs={2} container alignItems="center" style={{ paddingRight: "6px" }}>
              <Typography
                variant="h6"
                sx={{
                  color: "black",
                  flex: "0 0 auto",
                  paddingLeft: "12px",
                  fontSize: "14px",
                }}
              >
                地點:<span style={{ color: 'red' }}>*</span>
              </Typography>
              </Grid>

              <Grid item xs={10} container alignItems="center" style={{ paddingLeft: "14px" }}>
              <Select
                id="dropdown3c"
                value={selectedOptions3c}
                onChange={(event) => handleOptionsChange(event, "dropdown3c")}
                displayEmpty
                style={{
                  flex: "1",
                  padding: "5px",
                  borderRadius: "5px",
                  border: commonBorderStyle,
                  outline: "none",
                  color: commonColorStyle,
                  fontSize: "14px",
                  fontFamily: commonFontStyle,
                  height: "40px",
                }}
              >
                <MenuItem value="">請選擇</MenuItem>
                {dropdownset &&
                  dropdownset
                    .filter((item) => item.category_id === CATEGORY_ID_3) // Filter based on category ID
                    .map((item, index) => (
                      <MenuItem key={index} value={item.option_name}>
                        {item.option_name}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      {/* Divider */}
      <Divider variant="middle" style={{ margin: "16px 0" }} />

      {/*Field 4*/}
      <Grid container justifyContent="center">
        <Grid container spacing={2}>
          <Grid item xs={6} container alignItems="center">
            <Typography
              variant="h6"
              sx={{ color: "black", padding: commonPaddingStyle }}
            >
              有沒有曾經或現正接受標靶治療?<span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={6} container alignItems="center">
            <ToggleButtonGroup
              value={selectedOption4}
              exclusive
              onChange={handleOptionChange4}
              aria-label="gender"
              size="large"
              // Adjust size as needed
            >
              <ToggleButton
                value="Yes"
                sx={{
                  flex: "1",
                  marginRight: "10px",
                  height: "40px",
                  padding: "center",
                  borderRadius: "50px",
                  border: commonBorderStyle,
                }}
              >
                <Typography variant="h6" sx={{ color: commonColorStyle }}>
                  有
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>

            <ToggleButtonGroup
              value={selectedOption4}
              exclusive
              onChange={handleOptionChange4}
              aria-label="gender"
              size="large"
              // Adjust size as needed
            >
              <ToggleButton
                value="No"
                sx={{
                  flex: "1",
                  marginRight: "10px",
                  height: "40px",
                  borderRadius: "50px",
                  border: commonBorderStyle,
                }}
              >
                <Typography variant="h6" sx={{ color: commonColorStyle }}>
                  沒有
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>

            <ToggleButtonGroup
              value={selectedOption4}
              exclusive
              onChange={handleOptionChange4}
              aria-label="gender"
              size="large"
              // Adjust size as needed
            >
              <ToggleButton
                value="Maybe"
                sx={{
                  flex: "1",
                  height: "40px",
                  padding: "center",
                  borderRadius: "50px",
                  border: commonBorderStyle,
                }}
              >
                <Typography variant="h6" sx={{ color: commonColorStyle }}>
                  不知道
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>

        {showAdditionalInputs4 && (
          <Grid item xs={12} container alignItems="center" paddingTop={"12px"}>
            <Grid item xs={6} container alignItems="center">
              <Grid
                item
                xs={2}
                container
                alignItems="center"
                style={{ paddingRight: "6px" }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "black",
                    flex: "0 0 auto",
                    //paddingRight: "12px",
                    fontSize: "14px",
                  }}
                >
                  日期:<span style={{ color: 'red' }}>*</span>
                </Typography>
               </Grid>
               <Grid item xs={5} container alignItems="center" style={{ paddingLeft: "6px" }}>   
                <Select
                  id="dropdown1d"
                  value={selectedOptions1d}
                  onChange={(event) => handleOptionsChange(event, "dropdown1d")}
                  displayEmpty
                  style={{
                    flex: "1",
                    padding: "5px",
                    borderRadius: "5px",
                    border: commonBorderStyle,
                    outline: "none",
                    color: commonColorStyle,
                    fontSize: "14px",
                    fontFamily: commonFontStyle,
                    height: "40px",
                  }}
                >
                  <MenuItem value="">月份</MenuItem>
                  {dropdownset &&
                    dropdownset
                      .filter((item) => item.category_id === CATEGORY_ID_1) // Filter based on category ID
                      .map((item, index) => (
                        <MenuItem key={index} value={item.option_name}>
                          {item.option_name}
                        </MenuItem>
                      ))}
                </Select>
              </Grid>
              <Grid
                item
                xs={5}
                container
                alignItems="center"
                style={{ paddingLeft: "5px" }}
              >
                <Select
                  id="dropdown2d"
                  value={selectedOptions2d}
                  onChange={(event) => handleOptionsChange(event, "dropdown2d")}
                  displayEmpty
                  style={{
                    flex: "1",
                    padding: "5px",
                    borderRadius: "5px",
                    border: commonBorderStyle,
                    outline: "none",
                    color: commonColorStyle,
                    fontSize: "14px",
                    fontFamily: commonFontStyle,
                    height: "40px",
                  }}
                >
                  <MenuItem value="">年份</MenuItem>
                  {dropdownset &&
                    dropdownset
                      .filter((item) => item.category_id === CATEGORY_ID_2) // Filter based on category ID
                      .map((item, index) => (
                        <MenuItem key={index} value={item.option_name}>
                          {item.option_name}
                        </MenuItem>
                      ))}
                </Select>
              </Grid>
            </Grid>

            <Grid
              item
              xs={6}
              container
              alignItems="center"
              // style={{ paddingLeft: "5px" }}
            >
              <Grid item xs={2} container alignItems="center" style={{ paddingRight: "6px" }}>
              <Typography
                variant="h6"
                sx={{
                  color: "black",
                  flex: "0 0 auto",
                  paddingLeft: "12px",
                  fontSize: "14px",
                }}
              >
                地點:<span style={{ color: 'red' }}>*</span>
              </Typography>
              </Grid>
              <Grid item xs={10} container alignItems="center" style={{ paddingLeft: "14px" }}>
              <Select
                id="dropdown3d"
                value={selectedOptions3d}
                onChange={(event) => handleOptionsChange(event, "dropdown3d")}
                displayEmpty
                style={{
                  flex: "1",
                  padding: "5px",
                  borderRadius: "5px",
                  border: commonBorderStyle,
                  outline: "none",
                  color: commonColorStyle,
                  fontSize: "14px",
                  fontFamily: commonFontStyle,
                  height: "40px",
                }}
              >
                <MenuItem value="">請選擇</MenuItem>
                {dropdownset &&
                  dropdownset
                    .filter((item) => item.category_id === CATEGORY_ID_3) // Filter based on category ID
                    .map((item, index) => (
                      <MenuItem key={index} value={item.option_name}>
                        {item.option_name}
                      </MenuItem>
                    ))}
              </Select>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default TreatementQuestionsForm;
